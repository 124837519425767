<template>
  <div class="listener">
    <b-row class="text-center no-wrap">
      <b-col cols="2">
        <i @click="playAudio" class="material-icons pointer">play_arrow</i>
      </b-col>
      <b-col cols="2">
        <i class="material-icons">graphic_eq</i>
      </b-col>
      <b-col align-v="center" cols="8">
        <span class="label">{{ text ?? 'How are you today?'}}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Listener',
  props: { url: String, text: String },
  methods: {
    playAudio () {
      const audio = new Audio(this.url ?? 'https://api-ema.s3.amazonaws.com/Super+Admin/fc8cffa3-1ef9-464e-b2b1-06e811f59c1a/fable/fc8cffa3-1ef9-464e-b2b1-06e811f59c1a')
      audio.play()
    }
  }
}
</script>

<style lang="stylus" scoped>
.listener
  max-width 10%
  white-space nowrap
  align-self center

  .pointer
    cursor pointer
</style>
